<template>
  <v-sheet :height="height" v-if="todos" class="">
    <v-card-title class=" py-2" style="background-color: #b9e0e2;">
      <span class="text-uppercase titre--text" >
        TODO LIST ({{ todos.length }})</span
      >
      <v-spacer></v-spacer>
      <v-dialog v-model="dialogtodo" max-width="700">
        <template v-slot:activator="{ on }">
          <v-btn
            class="primary--text"
            icon
            v-on="on"
            @click="openDialog()"
            ><v-icon> mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="mb-2"> Tâches </v-card-title>
          <v-card-text align="center">
            <v-alert dense color="red" outlined type="info" width="500px"
              >Pour ajouter une tâche à effectuer sur un prochain CEN ou PE,
              passez par leur fiche respective et ajouter la tâche par le menu
              pour être notifié lors du renouvellement</v-alert
            >
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col cols="10" offset="1">
                <v-text-field
                  outlined
                  placeholder="Titre (Programme Entretien, prochain OT, CEN, Butée)"
                  dense
                  v-model="title"
                ></v-text-field>
                <v-textarea
                  outlined
                  v-model="description"
                  placeholder="Descriptif de la tâche à effectuer"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="cleanDialog()"> Annuler </v-btn>
            <v-btn color="primary" text @click="save()"> Enregistrer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>

    <v-list outlined :max-height="height1" style="overflow-y: auto">
      <div v-for="todo in todos" :key="todo.id">
        <v-list-item >
          <v-list-item-action>
            <v-checkbox
              :false-value="false"
              :true-value="true"
              @change="changeStatus(todo)"
              :color="checkStyle(todo)"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-subtitle
              class="text-overline"
              :style="todoStyle(todo)"
              >{{ todo.title }}</v-list-item-subtitle
            >
            <v-list-item-subtitle class="text-caption" :style="todoStyle(todo)">{{
              todo.description
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text :style="todoStyle(todo)">{{
              todo.createdAt | moment("calendar", null, {
                  sameDay: "[Aujourd'hui]",
                  lastDay: "[Hier]",
                  lastWeek: "dddd [dernier]",
                  sameElse: "DD MMMM",
                })
            }}</v-list-item-action-text>
          </v-list-item-action>
        </v-list-item> 
        <v-divider ></v-divider>
      </div>
    </v-list>
  </v-sheet>
</template>

<script>
import { todosQuery } from "../graphql/todo/todos";
import { createTodoMutation } from "../graphql/todo/create_todo";
import { switchTodoStatusMutation } from "../graphql/todo/switch_todo_status";

export default {
  name: "Todo",
  props: {
    aircraft: { required: true },
    cen: { required: false },
    maintenanceProgramme: { required: false },
  },
  data: () => ({
    todos: [],
    dialogtodo: false,
    title: "",
    description: "",
  }),
  methods: {
    todoStyle(todo) {
      if (todo.status == "close") {
        return "color:#c3c4c7; text-decoration-color:#3c4c7; text-decoration: line-through;";
      }
      return "";
    },
    checkStyle(todo) {
      if (todo.status == "close") {
        return "color:#f0f0f1; ";
      }
      return "";
    },
    openDialog() {
      if (this.maintenanceProgramme) {
        this.title = "Programme Entretien"
      }
      if (this.cen) {
        this.title = "CEN"
      }
      this.dialogtodo = true
    },
    cleanDialog() {
      this.dialogtodo = false;
      this.title = "";
      this.description = "";
    },
    changeStatus(todo) {
      console.log(todo);
      this.$apollo
        .mutate({
          mutation: switchTodoStatusMutation,
          variables: {
            input: {
              id: parseInt(todo.id, 10),
            },
          },
        })
        .then(() => {
          this.$apollo.queries.todos.refetch();
        });
    },
    save() {
      this.$apollo
        .mutate({
          mutation: createTodoMutation,
          variables: {
            input: {
              attributes: {
                aircraftId: parseInt(this.aircraft.id, 10),
                title: this.title,
                description: this.description,
                maintenanceProgrammeId: this.maintenanceProgramme ? parseInt(this.maintenanceProgramme.id, 10) : null,
                cenId: this.cen ? parseInt(this.cen.id, 10) : null,
              },
            },
          },
        })
        .then(() => {
          this.cleanDialog();
          this.$apollo.queries.todos.refetch();
        });
    },
  },
  computed: {
    height() {
      if (this.maintenanceProgramme) {
        return "175px";
      }
      return "335px"
    },
    height1() {
      if (this.maintenanceProgramme) {
        return "120px";
      }
      return "303px"
    },    
  },
  apollo: {
    todos: {
      query: todosQuery,
      variables() {
        return {
          aircraftId: parseInt(this.aircraft.id, 10),
          maintenanceProgrammeId: this.maintenanceProgramme ? parseInt(this.maintenanceProgramme.id, 10) : null,
          cenId: this.cen ? parseInt(this.cen.id, 10) : null,
        };
      },
    },
  },
};
</script>

<style>
</style>