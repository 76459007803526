<template>
  <v-col cols="12" >
    <v-sheet outlined>
      <v-card-title class="titre--text">
         <v-spacer></v-spacer>

        <v-col cols="5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
      </v-card-title>
      <v-data-table
				:headers="headers"
				:items="aircrafts"
				:footer-props="{
					'items-per-page-options': [18, 50, 100, -1],
					'items-per-page-text': 'lignes par page',
					'items-per-page-all-text': 'Tous',
				}"
				:search="search"
				:loading="isLoading"
				loading-text="1 avion, 10 avions, 50 avions, 150 avions...  Vous gerez Orly ma parole !"
				dense
				locale="fr-FR"
				fixed-header
				no-data-text="1 avion, 10 avions, 50 avions, 150 avions...  Vous gerez Orly ma parole !"
				no-results-text="Aucun résultat"
			>
				<template v-slot:item.immatriculation="{ item }">
					<v-btn
						dense
						small
						outlined
						color="primary"
						:to="{
							name: 'aeronef',
							params: { immatriculation: item.immatriculation, page: 'info' },
						}"
						>{{ item.immatriculation }}</v-btn
					>
				</template>
				<template v-slot:item.supervisor="{ item }" >	
          <span v-if="item.supervisor">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>
				<template v-slot:item.cell_fullName="{ item }" >	
          <span v-if="item.cell.partModel && item.cell.partModel.fullName">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>
				<template v-slot:item.cell_serialNumber="{ item }" >	
          <span v-if="item.cell.serialNumber">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>
				<template v-slot:item.cell_manufacturer="{ item }" >	
          <span v-if="item.cell.manufacturer">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>
				<template v-slot:item.cell_manufacturingDate="{ item }" >	
          <span v-if="item.cell.manufacturingDate">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>
				<template v-slot:item.motors_fullName="{ item }" >	
          <span v-if="item.motors.length > 0">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>
				<template v-slot:item.motors_serialNumber="{ item }" >	
          <span v-if="item.motors.length > 0 && item.motors[0].serialNumber">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>
				<template v-slot:item.propellers_fullName="{ item }" >	
          <span v-if="item.propellers.length > 0">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>
				<template v-slot:item.propellers_serialNumber="{ item }" >	
          <span v-if="item.propellers.length > 0 && item.propellers[0].serialNumber">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>

				<template v-slot:item.mtow="{ item }" >	
          <span v-if="item.mtow">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>

				<template v-slot:item.cell_manuals="{ item }" >	
          <span v-if="item.cell.manuals && item.cell.manuals.length > 0">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>

				<template v-slot:item.motors_manuals="{ item }" >	
          <span v-if="item.motors.length > 0 && item.motors[0].manuals.length > 0">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template> 

				<template v-slot:item.propellers_manuals="{ item }" >	
          <span v-if="item.propellers.length > 0 && item.propellers[0].manuals.length > 0">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
					<span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>

        <template v-slot:item.cover="{ item }" >	
          <span v-if="item.aircraftPhotos.find( (ap) => ap.isCover === true )">          
            <v-icon color="green">mdi-check</v-icon>
          </span>
          <span v-else>
            <v-icon color="red">mdi-close-circle</v-icon>
					</span>
        </template>

			</v-data-table>
    </v-sheet>
  </v-col>
</template>

<script>
import { aircraftsRequeteQuery } from "../../graphql/aircraft/aircrafts_requete";


export default {
  name: "plane",
	components: {
  },
  data() {
    return {
      search: "",
      isLoading: true,
			headers: [
				{
          text: "IMMAT",
          align: "center",
          value: "immatriculation",
          width: "50px",
        },
				{ text: "SUP", value: "supervisor", width: "50px" },
				{ text: "CELL", value: "cell_fullName", width: "50px" },
        { text: "S/N", value: "cell_serialNumber", width: "50px"},       
        { text: "Const", value: "cell_manufacturer", width: "50px"},       
        { text: "Fab", value: "cell_manufacturingDate", width: "50px"},       

        { text: "MOT", value: "motors_fullName", width: "50px"},
				{ text: "S/N", value: "motors_serialNumber", width: "50px"},       

        { text: "HEL", value: "propellers_fullName", width: "50px" },
        { text: "S/N", value: "propellers_serialNumber", width: "50px"},       
        { text: "MTOW", value: "mtow", width: "50px" }, 
        { text: "photo", value: "cover", width: "50px" },
        { text: "MMC", value: "cell_manuals", width: "50px" },
        { text: 'MMM', value: 'motors_manuals', width: "50px" }, 
        { text: 'MMH', value: 'propellers_manuals', width: "50px" }, 

      ],
    };
  },
  methods: {

  },
  apollo: {

    aircrafts: {
      query: aircraftsRequeteQuery,
			result() {
        this.isLoading = false;
      },      
    },
  },
};
</script>