<template  >

    <v-col :cols="colsize"  v-if="cen">
      <v-card-title>
        <v-row>
          <v-col cols="2" v-if="!cen.closedAt">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
               <v-btn outlined elevation="2" icon small color="red"  @click="generateDocument()" :loading="generateLoading">
                  <v-icon small  v-bind="attrs" v-on="on"
                    >mdi-autorenew</v-icon
                  >
                </v-btn>
              </template>
              <span>Regénèrer les fichiers</span>
            </v-tooltip>
 
          </v-col>
          <v-col cols="1" v-else>
            <v-icon >mdi-file-pdf-box</v-icon>
          </v-col>
          <v-col align="left">FICHIER PDF </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-list>
        <v-list-item-title>RAPPORT</v-list-item-title>
        <v-list-item v-if="cen.reportAttachment">
          <v-list-item-icon>
            <v-btn
              color="red"
              outlined
              small
              :href="cen.reportAttachment.fileUrl"
              target="_blank"
              ><v-icon dark color="red" class="mr-2"> mdi-file-pdf-box </v-icon>
              Rapport d'examen
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-icon>
            <v-btn color="grey" outlined>
              <v-icon dark class="mr-2" small> mdi-file-pdf-box </v-icon>Rapport pas
              encore généré
            </v-btn>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item-title>Certificat</v-list-item-title>
        <v-list-item v-if="cen.certificateAttachment">
          <v-list-item-icon>
            <v-btn
              color="red"
              outlined
              small
              :href="cen.certificateAttachment.fileUrl"
              target="_blank"
              v-if="cen.certificateAttachment"
            >
              <v-icon dark color="red" class="mr-2"> mdi-file-pdf-box </v-icon
              >Certificat C.E.N
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-icon>
            <v-btn color="grey" outlined>
              <v-icon dark class="mr-2" small> mdi-file-pdf-box </v-icon>CEN pas encore généré
            </v-btn>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item v-if="cen.etiquetteAttachment">
          <v-list-item-icon>
            <v-btn
              color="red"
              outlined
              small
              :href="cen.etiquetteAttachment.fileUrl"
              target="_blank"
              v-if="cen.etiquetteAttachment"
            >
              <v-icon dark color="red" class="mr-2"> mdi-file-pdf-box </v-icon
              >Etiquette
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-icon>
            <v-btn color="grey" outlined >
              <v-icon dark class="mr-2"> mdi-file-pdf-box </v-icon>Etiquette pas encore généré
            </v-btn>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item-title>Bordereau</v-list-item-title>
        <v-list-item v-if="cen.deliverySlipAttachment">
          <v-list-item-icon>
            <v-btn
              color="red"
              outlined
              small
              :href="cen.deliverySlipAttachment.fileUrl"
              target="_blank"
            >
              <v-icon dark color="red" class="mr-2"> mdi-file-pdf-box </v-icon
              >Bordereau FR
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-icon>
            <v-btn color="grey" outlined >
              <v-icon dark class="mr-2"> mdi-file-pdf-box </v-icon>Bordereau FR
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item v-if="cen.deliverySlipAttachment">
          <v-list-item-icon>
              <v-btn
                color="red"
                outlined
                small
                :href="cen.deliverySlipAttachment.fileUrl"
                target="_blank"
              >
                <v-icon dark color="red" class="mr-2"> mdi-file-pdf-box </v-icon
                >Bordereau EN
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-icon>
              <v-btn color="grey" outlined >
                <v-icon dark class="mr-2"> mdi-file-pdf-box </v-icon>Bordereau EN
              </v-btn>
            </v-list-item-icon>
          </v-list-item>

      <div v-if="cen.action !== 'renew'">
        <v-list-item v-if="cen.extensionFormAttachment">
          <v-list-item-icon>
            <v-btn
              color="red"
              outlined
              small
              :href="cen.extensionFormAttachment.fileUrl"
              target="_blank"
            >
              <v-icon dark color="red" class="mr-2"> mdi-file-pdf-box </v-icon
              >Formulaire d'extension
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-icon>
            <v-btn color="grey" outlined>
              <v-icon dark class="mr-2" > mdi-file-pdf-box </v-icon>Form pas
              encore généré
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </div>

      </v-list>    
   
    <v-snackbar
      v-model="snackbar"
      timeout="6000"
      rounded="pill"
      color="red"
      centered
      app
      text
    >
      <span class="text-center">{{ snackbarText }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>
 </v-col>
</template>

<script>
import { cenQuery } from "../../graphql/cen/cen";
import { partModelsQuery } from "@/graphql/part_model/part_models";
import { generateCenMutation } from "@/graphql/cen/generate_cen";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { meQuery } from "@/graphql/me";

export default {
  name: "DocumentPdf",
  components: {},
  props: ["colsize"],
  data: () => {
    return {
      cen: null,
      open: false,
      signedCertificate: null,
      snackbar: false,
      snackbarText: "",
      generateLoading: false,
    };
  },
  apollo: {
    me: {
      query: meQuery,
    },
    cen: {
      query: cenQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    partModels: {
      query: partModelsQuery,
      variables: {
        serie: false,
        type: ["Cell"],
        tcHolderId: null,
      },
    },
  },
  methods: {   
    generateDocument() {
      this.generateLoading = true;

      if (this.cen.totalOpenDeviations > 0) {
        this.snackbar = true;
        this.generateLoading = false;
        this.snackbarText = "La génération est bloquée car il y a des écarts ouverts.";

      } else {
        this.$apollo
          .mutate({
            mutation: generateCenMutation,
            variables: {
              input: {
                id: parseInt(this.cen.id, 10),
              },
            },
          })
          .then(() => {
            this.$apollo.queries.cen.refetch();
            this.snackbarText = "Les fichiers ont bien été générés";
            this.snackbar = true;
            this.generateLoading = false;
          });
       }
      },
  },
};
</script>