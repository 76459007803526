<template>
  <v-row>
    <v-col cols="6">
      <v-row>
        <v-col cols="12">
          <div class="mb-8" style="font-size:16px">Indiquez ou modifiez le propriétaire </div>
          <v-text-field
            label="Propriétaires"
            v-model="owner"
            append-outer-icon="mdi-plus-circle-outline"
            @click:append-outer="addData()"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="2">
        <v-btn fab x-small color="primary" @click="addData()">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-col> -->
      </v-row>
    </v-col>
    <v-col cols="6"> 
      <v-row>
        <v-col cols="12">
          <v-sheet outlined rounded min-height="250px">
          <v-simple-table dense class="ml-2">
            <thead>
              <tr>
                <th>Liste du/des Propriétaire(s)</th>
                <th width="50px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="d in formData" :key="d.owner">
                <td>{{ d.owner }}</td>
                <td>
                  <v-btn small icon color="red" @click="removeData(d)">
                    <v-icon >mdi-delete-forever-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table></v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: "Owners",
  props: ["ownersData"],
  components: {},
  data() {
    return {
      formData: [],
      owner: null,
    };
  },
  mounted() {
    this.formData = this.ownersData;
  },
  methods: {
    addData() {
      if (this.owner) {
        this.formData.push({ owner: this.owner });
        console.log(this.owner);
        console.log(this.formData);
        this.owner = null;
        this.$emit("update:ownersData", this.formData);
      }
    },
    removeData(d) {
      this.formData = this.formData.filter((f) => f.owner != d.owner);
      this.$emit("update:ownersData", this.formData);
    },
  },
};
</script>

