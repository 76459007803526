<template>
  <div>
    <v-alert
      border="left"
      dismissible
      prominent
      outlined
      color="primary"
    >
      <p>
        Un écart de niveau 1 correspond à un non-respect significatif des
        exigences applicables abaissant le niveau de sécurité et compromettant
        gravement la sécurité du vol.
      </p>
      <p>
        Un écart de niveau 2 correspond à un non-respect des exigences
        applicables susceptible d'abaisser le niveau de sécurité et de
        compromettre la sécurité du vol.
      </p>
      <p>
        Ainsi, toute anomalie/défaut ne constituant pas un écart tel que décrit
        ci-dessus et ne doit pas faire l’objet d’un enregistrement en tant
        qu’écart mais peut en revanche faire l’objet
        <strong
          >d’une observation enregistrée dans l'onglet "Observations"</strong
        >
        ci-dessous dont la responsabilité de la prise en compte revient au
        gestionnaire de la navigabilité de l’aéronef.
      </p>
    </v-alert>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left text-uppercase" style="width: 30px">N°</th>
            <th class="text-uppercase" style="width: 30px">Niv.</th>
            <th style="width: 250px" class="text-left text-uppercase">
              Item concerné
            </th>
            <th class="text-uppercase">description</th>
            <th class="text-uppercase">Action correctives</th>
            <th style="width: 120px" class="text-uppercase">Photos</th>
            <th style="width: 100px" class="text-uppercase">Date clotûre</th>
            <th style="width: 100px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ecart in values" :key="ecart.id">
            <td class="font-weight-bold red--text">{{ ecart.number }}</td>
            <td>
              {{ ecart.deviationLevel == 3 ? "Obs." : ecart.deviationLevel }}
            </td>
            <td>{{ ecart.cenCheckValue.title }}</td>
            <td class="editor" v-html="ecart.deviation"></td>
            <td class="editor" v-html="ecart.deviationCorrection"></td>
            <td>
              <v-list dense v-if="ecart.deviationCorrectionPhotos">
                <v-list-item
                  v-for="(photo, index) in ecart.deviationCorrectionPhotos"
                  :key="photo.id"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-dialog>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text v-bind="attrs" v-on="on"
                            >Photo #{{ index + 1 }}</v-btn
                          >
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <v-card-title>Photo #{{ index + 1 }}</v-card-title>
                            <v-card-text>
                              <v-img :src="photo.fileUrl"></v-img>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn text @click="dialog.value = false" 
                                >Close</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn class="ml-4" icon @click="deletePhoto(photo)">
                      <v-icon color="red" small>mdi-close-thick</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item></v-list
              >
            </td>
            <td>
              <span v-if="ecart.deviationClosedAt">{{
                ecart.deviationClosedAt | moment("DD/MM/YYYY")
              }}</span>
            </td>
            <td>
              <v-btn x-small outlined @click="openEcart(ecart)" color="red">
                Clôturer
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-card-title>
          {{ dialogEcartTitle }}
        </v-card-title>
        <v-divider class="mb-8"></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dialogDatePicker"
                    label="Date de cloture"
                    prepend-icon="mdi-calendar-month"
                    v-on="on"
                    outlined
                    readonly
                    dense
                    :error-messages="dialogDatePErrors"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dialogDate"
                  :first-day-of-week="1"
                  locale="fr"
                  color="green"
                  no-title
                  @input="menu = false"
                >
                </v-date-picker> </v-menu
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <tiptap-vuetify
                v-model="dialogActionCorrective"
                :extensions="extensions"
                :card-props="{ outlined: true, color: '#eceff1' }"
                :toolbar-attributes="{ dense: true, color: 'white' }"
                placeholder="Indiquez les actions correctives"
                :error-messages="dialogActionCorrectivePErrors"
            />
            <p
                v-if="dialogActionCorrectivePErrors.length > 0"
                style="font-weight: bold; color: red"
                align="center"
              >
                {{ dialogActionCorrectivePErrors[0] }}
              </p>
          </v-col>
          </v-row>
          <v-row>
            <dashboard-modal
              :uppy="uppy"
              :open="open"
              :props="{ trigger: '#dashModal' }"
            />
            <v-btn
              id="dashModal"
              class="select-file-button ma-4 white--text"
              color="primary"
              x-small
              >Ajout de photo<v-icon dark right
                >mdi-camera-plus-outline</v-icon
              ></v-btn
            >
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> Annuler </v-btn>
          <v-btn color="primary" text @click="updateDeviation(dialogEcart)">
            Fermer l'écart
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import { cenCheckValueItemsQuery } from "@/graphql/cen/cen_check_value_items";
import { updateCenCheckValueItemMutation } from "@/graphql/cen/update_cen_check_value_item";
import { deleteAttachementMutation } from "@/graphql/delete_attachement";
import { DashboardModal } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import Uppy from "@uppy/core";
import confirm from "@/components/confirm.vue";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ecarts",
  props: ["cen"],
  mixins: [validationMixin],
  components: {
    DashboardModal,
    TiptapVuetify,
    confirm,
  },
  data() {
    return {
      cenCheckValueItems: [],
      dialog: false,
      dialogEcartTitle: null,
      dialogActionCorrective: null,
      dialogEcart: null,
      dialogPhotoId: [],
      menu: false,
      dialogDate: null,
      open: false,
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
      ],
    };
  },
  validations: {
    dialogDate: { required },
    dialogActionCorrective: { required },
  },
  computed: {
    dialogDatePicker() {
      return this.formatDate(this.dialogDate);
    },
    values: function () {
      const val = [...this.cenCheckValueItems];
      return val.sort((a, b) => a.number - b.number);
    },
    uppy: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: true,
        width: "150px",
        height: "50px",
        inline: false,
        locale: {
          strings: {
            dropHereOr: "Glisser votre photo ou %{browse}",
            browse: "Sélectionner",
          },
        },
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          result.successful.forEach((photo) => {
            self.dialogPhotoId.push(photo.response.signed_id);
          });
          console.log(self.dialogPhotoId);
        }),
    dialogDatePErrors() {
      const errors = [];
      if (!this.$v.dialogDate.$dirty) return errors;
      !this.$v.dialogDate.required && errors.push("titre manquant.");
      return errors;
    },
    dialogActionCorrectivePErrors() {
      const errors = [];
      if (!this.$v.dialogActionCorrective.$dirty) return errors;
      !this.$v.dialogActionCorrective.required && errors.push("action corrective manquant.");
      return errors;
    },
  },
  apollo: {
    cenCheckValueItems: {
      query: cenCheckValueItemsQuery,
      variables() {
        return {
          cenId: this.cen.id,
          value: "not_conform",
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
  methods: {
    openEcart: function (ecart) {
      this.dialog = true;
      this.dialogEcartTitle = ecart.cenCheckValue.title;
      this.dialogEcart = ecart;
      this.dialogActionCorrective = ecart.deviationCorrection;
      this.dialogDate = ecart.deviationClosedAt;
      this.dialogPhotoId = ecart.deviationCorrectionPhotos.map(
        (p) => p.fileSignedId
      );
    },
    closeDialog: function () {
      this.dialog = false;
      this.dialogEcartTitle = null;
      this.dialogDate = null;
      this.dialogActionCorrective = null;
      this.dialogEcart = null;
      this.dialogPhotoId = [];
      this.$v.$reset();
    },
    updateDeviation: function (val) {
      console.log(val);
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$apollo
          .mutate({
            mutation: updateCenCheckValueItemMutation,
            variables: {
              input: {
                id: parseInt(val.id, 10),
                attributes: {
                  deviationCorrection: this.dialogActionCorrective,
                  deviationClosedAt: this.dialogDate,
                  deviationCorrectionPhotos: this.dialogPhotoId,
                },
              },
            },
          })
          .then(() => {
            this.closeDialog();
          });
      }
    },
    deletePhoto: function (photo) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteAttachementMutation,
                variables: {
                  input: {
                    id: parseInt(photo.id, 10),
                  },
                },
              })
              .then(() => {
                this.$apollo.queries.cenCheckValueItems.refetch();
              });
          }
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
</style>