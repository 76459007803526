import gql from 'graphql-tag';
import { todoFragment } from './fragment_todo';

export const createTodoMutation = gql`
  mutation createTodo($input: CreateTodoInput!) {
    createTodo(input: $input) {
      todo {
        ...todo
      }
    }
}
${todoFragment.fragments.todo}`