<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-row>
          <v-col cols="12">
            <ProfilCard />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <HDVvalidate />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <OTvalidate />
          </v-col>
        </v-row>


      </v-col>
      
      <v-col cols="3">
        <v-row>
          <v-col cols="12">
            <NextCen /> 
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <NextPe />
          </v-col>
        </v-row>        
      </v-col>

      <v-col cols="3">
        <v-row>
          <v-col cols="12">
            <NextVisite />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <PendingOT />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3">
        <v-row>
          <v-col cols="12">
            <v-sheet outlined rounded min-height="800">
              <v-card-title class="text-uppercase justify-center primary--text">
                NOTES PERSO
                <v-dialog v-model="dialognote" max-width="700">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="primary--text ml-4"
                        icon
                        v-on="on"
                        @click="dialognote = !dialognote"
                        ><v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title> Notes </v-card-title>
                      <v-divider class="mb-8"></v-divider>
                      <v-card-text>
                        <v-row>
                          <v-col cols="10" offset="1">
                            <!-- <v-textarea
                              style="padding: 0 16px 0 16px"
                              placeholder="Ajoutez un commentaire"
                              rows="5"
                              auto-grow
                              outlined
                              v-model="notes"
                            ></v-textarea> -->
                            <tiptap-vuetify
                              v-model="notes"
                              :extensions="extensions"
                              :card-props="{ outlined: true }"
                              :toolbar-attributes="{ dense: true, color: '#eceff1' }"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="dialognote = false"
                        >
                          Annuler
                        </v-btn>
                        <v-btn color="primary" text @click="saveNotes()">
                          Enregistrer
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  </v-card-title>
                 <v-card-text>
                  <p v-if="notes" v-html="notes.replaceAll('\n','<br />')"> {{ notes }}</p>
                </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
     
    </v-row>
  </v-container>
</template>

<script>
import NextVisite from "./NextVisite.vue";
import NextCen from "./NextCen.vue";
import NextPe from "./NextPe.vue";
import PendingOT from "./PendingOT.vue";
import OTvalidate from "./OTvalidate.vue";
import HDVvalidate from "./HDVvalidate.vue";
import ProfilCard from "./ProfilCard.vue";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  Strike,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  Heading,
} from "tiptap-vuetify";

export default {
  name: "GestionHome",
  components: {
    NextVisite,
    PendingOT,
    OTvalidate,
    HDVvalidate,
    ProfilCard,
    NextCen,
    NextPe,
    TiptapVuetify,
  },
  metaInfo() {
    return {
      title: "Igame - Gestionnaire",
    };
  },
  data: () => ({
    dialognote: false,
    notes: null,
    extensions: [
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
        [
          Heading,
          {
            options: {
              levels: [1, 2],
            },
          },
        ],
      ],
  }),  
};
</script>