import gql from 'graphql-tag';

export const todoFragment = {
    fragments: {
      todo: gql`
      fragment todo on Todo {
        id
        title
        description
        status
        createdAt
        closedAt
        user {
          id
        }
        aircraft {
          id
          immatriculation
        }
      }` 
    }
}