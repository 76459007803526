<template>
  <v-card outlined min-height="125px">
      <v-card-title class="primary--text justify-center ">Nouvelle(s) Heure(s) de vol
      </v-card-title>
      <!-- <div v-if="$apollo.loading">
        <v-row>
          <v-skeleton-loader
            ref="skeleton"
            type="table"
            class="mx-auto"
          ></v-skeleton-loader>
        </v-row>
      </div> -->
			<v-data-table
        no-data-text="Aucune(s) heure(s) à valider"
        hide-default-footer
        dense
        class="mb-2"
        locale="fr-FR"
      >
			</v-data-table>
    </v-card>

</template>

<style scoped>
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>

export default {
  name: "HDVvalidate",
  data() {
		return {
			headers: [
        { text: "DATE", value: "" },
        { text: "IMMAT", value: "" },
        { text: "NB HEURE", value: "" },
			],
		};
  },
  apollo: {

  },
};
</script>
