import gql from 'graphql-tag';
import { todoFragment } from './fragment_todo';


export const switchTodoStatusMutation = gql`
  mutation switchTodoStatus($input: SwitchTodoStatusInput!) {
    switchTodoStatus(input: $input) {
      todo {
        ...todo
      }
    }
}
${todoFragment.fragments.todo}`