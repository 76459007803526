<template>
  <v-container fluid class="background">
    <v-row v-if="aircraft">
      <v-col :cols="colsize">
        <v-row>
          <v-col cols="12">
            <v-sheet outlined min-height="730px">
              <v-card-title style="background-color: #b9e0e2">
                <span
                  class="ml-16 pl-8 titre--text font-weight-bold text-center"
                  >INFOS</span
                >
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  align="right"
                  :to="{
                    name: 'aeronef_edit',
                    params: { id: aircraft.immatriculation },
                  }"
                  ><v-icon color="#5c5d8d">mdi-pencil</v-icon></v-btn
                >
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-list-item>
                  <v-list-item-icon>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-airport</v-icon>
                      </template>
                      <span>Dernière heure de vol</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="aircraft.flies.length > 0">
                      {{ aircraft.lastFly.flyDate | moment("DD/MM/YYYY") }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle> </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                          >mdi-card-account-details-outline</v-icon
                        >
                      </template>
                      <span>Client</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="aircraft.contact">
                      <router-link
                        :to="{
                          name: 'contact',
                          params: { id: aircraft.contact.id },
                        }"
                        >{{ aircraft.contact.name }}</router-link
                      >
                    </v-list-item-subtitle>
                    <v-list-item-subtitle id="nonrens" v-else
                      >Pas de contrat</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                          >mdi-account-edit-outline</v-icon
                        >
                      </template>
                      <span>Gestionnaire</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <span v-if="aircraft.supervisor">{{
                        aircraft.supervisor.name
                      }}</span>
                      <span id="nonrens" v-else
                        ><em>Non renseigné</em></span
                      ></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-tools</v-icon>
                      </template>
                      <span>Atelier/meca</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <span v-if="aircraft.workshop">
                        <router-link
                          :to="{
                            name: 'contact',
                            params: { id: aircraft.workshop.id },
                          }"
                          >{{ aircraft.workshop.name }}</router-link
                        >
                      </span>
                      <span id="nonrens" v-else
                        ><em>Atelier non renseigné</em></span
                      >
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="aircraft.workshop">
                      <span v-if="aircraft.workshop.easaNumber">{{
                        aircraft.workshop.easaNumber
                      }}</span>
                      <span v-else>Sans agrément</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                          >mdi-airplane-search</v-icon
                        >
                      </template>
                      <span>Aéronef</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <span
                        >{{ aircraft.easaType }} ({{ aircraft.easaPartType }})
                      </span></v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      v-if="
                        aircraft.cell &&
                        aircraft.cell.partModel &&
                        aircraft.cell.partModel.pressurized
                      "
                    >
                      <span>Pressurisé </span></v-list-item-subtitle
                    >
                    <v-list-item-subtitle v-if="aircraft.annex1 == true"
                      >Annexe 1</v-list-item-subtitle
                    >
                    <!-- <v-list-item-subtitle>
                      <span
                        >Annexe 1
                      </span></v-list-item-subtitle
                    > -->
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-scale</v-icon>
                      </template>
                      <span>MTOW</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <span v-if="aircraft.mtow"
                        >{{ aircraft.mtow }} kg</span
                      ></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                          >mdi-shield-airplane-outline</v-icon
                        >
                      </template>
                      <span>TCDS</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <span
                        >Tcds : {{ aircraft.cell.partModel.tcds }}</span
                      ></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                          >mdi-gas-station</v-icon
                        >
                      </template>
                      <span>Carburant</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <span v-if="aircraft.gasType">{{
                        aircraft.gasType.join(" / ")
                      }}</span>
                      <span id="nonrens" v-else
                        ><em>Type non renseigné</em></span
                      ></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-parking</v-icon>
                      </template>
                      <span>Parking</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <span v-if="aircraft.parkingType">{{
                        aircraft.parkingType
                      }}</span>
                      <span id="nonrens" v-else
                        ><em>Lieu non renseigné</em></span
                      ></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>

              <v-card-actions align="end">
                <v-spacer></v-spacer>
                <archive-reason-modal
                  :aircraft="aircraft"
                ></archive-reason-modal>
              </v-card-actions>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>

      <v-col :cols="colsize">
        <v-row>
          <v-col cols="12">
            <v-sheet outlined min-height="730px">
              <v-card-title style="background-color: #b9e0e2"
                ><span class="titre--text pl-10">DOCUMENTS</span>
                <v-spacer></v-spacer>
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon align="right">
                        <v-icon color="#456990" v-bind="attrs" v-on="on"
                          >mdi-autorenew</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Regénèrer les situations</span>
                  </v-tooltip>
                </div>
              </v-card-title>

              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-icon class="mr-4">
                  <v-btn
                    v-if="aircraft.contract && aircraft.contract.document"
                    :href="aircraft.contract.document"
                    target="_blank"
                    icon
                  >
                    <v-icon color="red">mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <span v-else class="pl-1">
                    <v-icon dark class="primary--text">mdi-file-pdf-box</v-icon>
                  </span>
                </v-list-item-icon>
                <v-list-item-content v-if="aircraft.contract">
                  <v-list-item-subtitle
                    v-if="aircraft.contract.register == true"
                    >Contrat déposé (
                    {{ aircraft.contract.contractType }} )</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    v-if="aircraft.contract.register == false"
                    >Contrat commercial (
                    {{ aircraft.contract.contractType }} )</v-list-item-subtitle
                  >
                  <v-list-item-subtitle>
                    <router-link
                      :to="{
                        name: 'contrat',
                        params: { id: aircraft.contract.id },
                      }"
                      >{{ aircraft.contract.number }}</router-link
                    >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Depuis le
                    {{ aircraft.aircraftContract.start | moment("DD/MM/YYYY") }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content v-else class="pl-2">
                  <v-list-item-subtitle>Contrat</v-list-item-subtitle>
                  <v-list-item-subtitle
                    ><span id="nonrens"
                      ><em>Aucun contrat</em></span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="aircraft.lastCen">
                <v-list-item-icon class="mr-4">
                  <v-btn
                    icon
                    color="red"
                    target="_blank"
                    v-if="aircraft.lastCen.certificateAttachment"
                    :href="aircraft.lastCen.certificateAttachment.fileUrl"
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <router-link
                      :to="{
                        name: 'cen',
                        params: { id: aircraft.lastCen.id },
                      }"
                      >{{ aircraft.lastCen.reference }}</router-link
                    >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    >Expire le
                    {{
                      aircraft.lastCen.expiredAt | moment("DD/MM/YYYY")
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else>
                <v-list-item-icon class="mr-4">
                  <v-btn
                    v-if="findDoc('Cen')"
                    icon
                    color="red"
                    target="_blank"
                    :href="findDoc('Cen').document"
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <span v-else class="pl-1">
                    <v-icon dark class="primary--text">mdi-file-pdf-box</v-icon>
                  </span>
                </v-list-item-icon>
                <v-list-item-content v-if="findDoc('Cen')">
                  <v-list-item-subtitle>C.E.N</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="findDoc('Cen').expiredAt"
                    >Expire le
                    {{
                      findDoc("Cen").expiredAt | moment("DD/MM/YYYY")
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-content v-else class="pl-2">
                  <v-list-item-subtitle>C.E.N</v-list-item-subtitle>
                  <v-list-item-subtitle
                    ><span id="nonrens"
                      ><em>CEN non ajouté</em></span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="aircraft.lastMaintenanceProgramme">
                <v-list-item-icon class="mr-4">
                  <v-btn
                    v-if="aircraft.lastMaintenanceProgramme.signedProgramme"
                    icon
                    color="red"
                    target="_blank"
                    :href="aircraft.lastMaintenanceProgramme.signedProgramme"
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <span v-else class="pl-1">
                    <v-icon dark class="primary--text">mdi-file-pdf-box</v-icon>
                  </span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle
                    v-if="aircraft.lastMaintenanceProgramme.reference"
                  >
                    <router-link
                      :to="{
                        name: 'pe',
                        params: { id: aircraft.lastMaintenanceProgramme.id },
                      }"
                      >{{
                        aircraft.lastMaintenanceProgramme.reference
                      }}</router-link
                    >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else
                    >Programme d'entretien</v-list-item-subtitle
                  >

                  <v-list-item-subtitle
                    >Expire le
                    {{
                      aircraft.lastMaintenanceProgramme.expiredAt
                        | moment("DD/MM/YYYY")
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else>
                <v-list-item-icon class="mr-4">
                  <v-btn
                    v-if="findDoc('Pe')"
                    icon
                    color="red"
                    target="_blank"
                    :href="findDoc('Pe').document"
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <span v-else class="pl-1">
                    <v-icon dark class="primary--text">mdi-file-pdf-box</v-icon>
                  </span>
                </v-list-item-icon>
                <v-list-item-content v-if="findDoc('Pe')">
                  <v-list-item-title>Programme d'entretien</v-list-item-title>
                  <v-list-item-subtitle
                    >Expire le
                    {{
                      findDoc("Pe").expiredAt | moment("DD/MM/YYYY")
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-content v-else class="pl-2">
                  <v-list-item-subtitle
                    >Programme d'entretien</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    ><span id="nonrens"
                      ><em>PE non ajouté</em></span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="mr-4">
                  <v-btn icon color="red" target="_blank">
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <!-- <span v-else class="pl-1">
                    <v-icon dark class="primary--text">mdi-file-pdf-box</v-icon>
                  </span> -->
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Situation prévisionnelle</v-list-item-subtitle
                  >
                  <v-list-item-subtitle>généré le </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="mr-4">
                  <v-btn
                    v-if="aircraft.kardexAttachment"
                    icon
                    color="red"
                    target="_blank"
                    :href="aircraft.kardexAttachment.fileUrl"
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <span v-else class="pl-1">
                    <v-icon dark class="primary--text">mdi-file-pdf-box</v-icon>
                  </span>
                </v-list-item-icon>
                <v-list-item-content v-if="aircraft.kardexAttachment">
                  <v-list-item-title>Situation technique </v-list-item-title>
                  <v-list-item-subtitle
                    >généré le
                    {{
                      aircraft.kardexAttachment.createdAt | moment("DD/MM/YYYY")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else class="pl-2">
                  <v-list-item-title>Situation technique </v-list-item-title>
                  <v-list-item-subtitle
                    ><span id="nonrens"
                      ><em>Situation non généré</em></span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="mr-4">
                  <span class="pl-1">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-bookshelf</v-icon>
                      </template>
                      <span>Manuel cellule</span>
                    </v-tooltip>
                  </span>
                </v-list-item-icon>
                <v-list-item-content class="pl-2">
                  <template v-if="aircraft.cell.manuals.length > 0">
                    <v-list-item-subtitle
                      v-for="manual in aircraft.cell.manuals"
                      :key="manual.id"
                    >
                      <router-link
                        :to="{ name: 'document', params: { id: manual.id } }"
                        >{{ manual.number }} - {{ manual.title }}</router-link
                      >
                    </v-list-item-subtitle>
                  </template>
                  <v-list-item-subtitle v-else>
                    <span id="nonrens"><em>Pas de manuel cellulle</em></span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon class="mr-4">
                  <span class="pl-1">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-bookshelf</v-icon>
                      </template>
                      <span>Manuel Moteur</span>
                    </v-tooltip>
                  </span>
                </v-list-item-icon>
                <!-- <v-list-item-content>
                  <v-list-item-title>titre  Manuel Moteur </v-list-item-title>
                  <v-list-item-subtitle 
                    >révision du 
                  </v-list-item-subtitle>
                </v-list-item-content> -->
                <v-list-item-content class="pl-2">
                  <template
                    v-if="
                      aircraft.motors.length > 0 &&
                      aircraft.motors[0].manuals.length > 0
                    "
                  >
                    <v-list-item-subtitle
                      v-for="manual in aircraft.motors[0].manuals"
                      :key="manual.id"
                    >
                      <router-link
                        :to="{ name: 'document', params: { id: manual.id } }"
                        >{{ manual.number }} - {{ manual.title }}</router-link
                      >
                    </v-list-item-subtitle>
                  </template>
                  <v-list-item-subtitle v-else>
                    <span id="nonrens"><em>Pas encore selectionné</em></span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon class="mr-4">
                  <!-- <v-btn
                    icon
                    color="red"
                    target="_blank"
                    :href="aircraft.deviation"
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn> -->
                  <span class="pl-1">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-bookshelf</v-icon>
                      </template>
                      <span>Manuel Hélice</span>
                    </v-tooltip>
                  </span>
                </v-list-item-icon>
                <!-- <v-list-item-content>
                  <v-list-item-title>Manuel Hélice </v-list-item-title>
                  <v-list-item-subtitle 
                    >révision du 
                  </v-list-item-subtitle>
                </v-list-item-content> -->
                <v-list-item-content class="pl-2">
                  <template
                    v-if="
                      aircraft.propellers.length > 0 &&
                      aircraft.propellers[0].manuals.length > 0
                    "
                  >
                    <v-list-item-subtitle
                      v-for="manual in aircraft.propellers[0].manuals"
                      :key="manual.id"
                    >
                      <router-link
                        :to="{ name: 'document', params: { id: manual.id } }"
                        >{{ manual.number }} - {{ manual.title }}</router-link
                      >
                    </v-list-item-subtitle>
                  </template>
                  <v-list-item-subtitle v-else
                    ><span id="nonrens"
                      ><em>Pas encore selectionné</em></span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            <v-sheet outlined min-height="370px">
              <v-card-title
                class="justify-center titre--text"
                style="background-color: #b9e0e2"
              >
                DERNIERES ACTIVITES
              </v-card-title>
              <v-divider></v-divider>
              <v-list
                dense
                subheader
                max-height="303px"
                style="overflow-y: auto"
              >
                <v-subheader class="btn"> 18 janvier 2021 </v-subheader>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>ORDRE DE TRAVAIL</v-list-item-title>
                    <v-list-item-subtitle class="text-wrap"
                      >Envoi de l'OT 2021-01</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>DEPOSE</v-list-item-title>
                    <v-list-item-subtitle class="text-wrap"
                      >Dépose du moteur LYCOMING O-235 H2C S/N:
                      1234</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-subheader class="btn"> 01 janvier 2021 </v-subheader>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>DEPOSE</v-list-item-title>
                    <v-list-item-subtitle class="text-wrap"
                      >Dépose du moteur LYCOMING O-235 H2C S/N:
                      1234</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>DEPOSE</v-list-item-title>
                    <v-list-item-subtitle class="text-wrap"
                      >Dépose du moteur LYCOMING O-235 H2C S/N:
                      1234</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col>

          <v-col cols="6">
            <v-sheet outlined height="370px">
              <v-card-title style="background-color: #b9e0e2">
                <span
                  class="ml-16 pl-8 text-uppercase justify-center titre--text"
                >
                  NOTES</span
                >
                <v-spacer></v-spacer>
                <v-dialog v-model="dialognote" max-width="700">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="primary--text"
                      icon
                      v-on="on"
                      @click="dialognote = !dialognote"
                      ><v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title> Notes </v-card-title>
                    <v-divider class="mb-8"></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col cols="10" offset="1">
                          <tiptap-vuetify
                            v-model="notes"
                            :extensions="extensions"
                            :card-props="{ outlined: true }"
                            :toolbar-attributes="{
                              dense: true,
                              color: '#eceff1',
                            }"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialognote = false">
                        Annuler
                      </v-btn>
                      <v-btn color="primary" text @click="saveNotes()">
                        Enregistrer
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <p v-if="notes" v-html="notes.replaceAll('\n', '<br />')"></p>
              </v-card-text>
            </v-sheet>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col>
                <todo :aircraft="aircraft" v-if="aircraft"></todo>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
#nonrens {
  font-style: italic;
  color: red;
}
</style>

<script>
import { aircraftQuery } from "../../../graphql/aircraft/aircraft";
import { updateAircraftMutation } from "../../../graphql/aircraft/update_aircraft";
import ArchiveReasonModal from "./ArchiveReasonModal.vue";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  Strike,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  Heading,
} from "tiptap-vuetify";
import Todo from "../../../components/todo.vue"

export default {
  components: {
    ArchiveReasonModal,
    TiptapVuetify,
    Todo
  },
  data: () => ({
    dialog: false,
    gestionnaire: null,
    atelier: null,
    dialognote: false,
    note: null,        
    extensions: [
      Bold,
      Italic,
      Underline,
      Strike,
      ListItem,
      BulletList,
      OrderedList,
      HardBreak,
      [
        Heading,
        {
          options: {
            levels: [1, 2],
          },
        },
      ],
    ],
  }),
  methods: {
    showHours(aircraftPart) {
      if (aircraftPart.flyValue.length > 0) {
        console.log(aircraftPart.flyValue);
        const flyValue = aircraftPart.flyValue.find(
          (f) => f.unit_code == "hours"
        );
        console.log(flyValue);
        var str = "";
        str += Math.floor(flyValue.current / 60);
        str += " h ";
        str += flyValue.current % 60;
        return str;
      } else {
        return "";
      }
    },
    findDoc(type) {
      const doc = this.aircraft.aircraftDocuments.find(
        (d) => d.typeName == type
      );
      if (doc) {
        return doc;
      }
      return null;
    },
    saveNotes() {
      this.$apollo
        .mutate({
          mutation: updateAircraftMutation,
          variables: {
            input: {
              id: parseInt(this.aircraft.id, 10),
              attributes: {
                notes: this.notes,
              },
            },
          },
        })
        .then(() => {
          this.dialognote = false;
        });
    },
  },
  computed: {
    colsize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 3;
        case "xl":
          return 3;
      }
      return "4";
    },    
  },
  apollo: {
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.$route.params.immatriculation,
        };
      },
      result({ data }) {
        this.notes = data.aircraft.notes;
      },
    },
  },
};
</script>