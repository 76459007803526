<template>
  <v-card outlined min-height="350px" >
      <v-card-title class="primary--text text-uppercase justify-center ">Ordre de Travail en attente
      </v-card-title>
      <!-- <div v-if="$apollo.loading">
        <v-row>
          <v-skeleton-loader
            ref="skeleton"
            type="table"
            class="mx-auto"
          ></v-skeleton-loader>
        </v-row>
      </div> -->
			<v-data-table
        no-data-text="Aucun(s) OT(s) en attente de retour"
        hide-default-footer
        dense
        class="mb-2"
        locale="fr-FR"
        :items="pendingOT"
        :headers="headers"
        height="320px"
        
      > 
			</v-data-table>
    </v-card>

</template>

<style scoped>
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>

export default {
  name: "PendingOT",
  data() {
		return {
			headers: [
        { text: "IMMAT.", value: "" },
        { text: "REF", value: "" },
        { text: "DEPUIS", value: "" },
			],

		};
  },
  apollo: {

  },
};
</script>
