<template>
  <v-card class="mt-6" min-height="400">
    <v-card-title class="text-h4 align-start">
      <v-sheet
        outlined
        overflow="hidden"
        color="primary"
        border-color="primary"
        elevation="6"
        rounded
        height="60"
        width="60"
        class="mt-n8"
      >
        <div class="pl-4 pt-2">
          <v-icon dark>mdi-exit-run</v-icon>
        </div>
      </v-sheet>
    </v-card-title>
    <v-card-text>
      <apexchart
        width="500"
        type="pie"
        :options="options"
        :series="series"
      ></apexchart>
    </v-card-text>
  </v-card>
</template>

<style>
.v-sheet.v-card {
  border-radius: 6px;
}
</style>

<script>
import { archiveReasonsQuery } from "../../graphql/aircraft/archive_reasons";
import VueApexCharts from "vue-apexcharts";

export default {
  components: { apexchart: VueApexCharts },
  data() {
    return {
      options: {
        chart: {
          type: 'pie',
          height: 350,
        },
        title: {
          text: 'Raison des départs',
          align: 'center'
        },
        labels: [
          'Cassé',
          'Vendu',
          'Non renouvelé Client',
          'Non renouvelé Sega',
          'rupture contrat Client',
          'rupture contrat Sega',
          'Autre'
        ],
        plotOptions: {
          pie: {
            size:160,
          }
        },
        colors: ['#b30000', '#7c1158', '#4421af', '#1a53ff',  '#00b7c7', '#27ae60',  '#ebdc78']
      },
      series: [],
    };
  },
  apollo: {
    archiveReasons: {
      query: archiveReasonsQuery,
      result(data) {
        if (data) {
          const broken = data.data.archiveReasons.filter(
            (r) => r.reason === "broken"
          ).length;
          const sell = data.data.archiveReasons.filter(
            (r) => r.reason === "sell"
          ).length;
          const no_renew_client = data.data.archiveReasons.filter(
            (r) => r.reason === "no_renew_client"
          ).length;
          const no_renew_sega = data.data.archiveReasons.filter(
            (r) => r.reason === "no_renew_sega"
          ).length;
          const cancel_client = data.data.archiveReasons.filter(
            (r) => r.reason === "cancel_client"
          ).length;
          const cancel_sega = data.data.archiveReasons.filter(
            (r) => r.reason === "cancel_sega"
          ).length;
          const other = data.data.archiveReasons.filter(
            (r) => r.reason === "other"
          ).length;
          this.series = [
            broken,
            sell,
            no_renew_client,
            no_renew_sega,
            cancel_client,
            cancel_sega,
            other,
          ];
        }
      },
    },
  },
};
</script>