<template>
  <v-card outlined min-height="125px">
      <v-card-title class="primary--text justify-center ">Nouvelle(s) demande d'OT(s)
      </v-card-title>
      <!-- <div v-if="$apollo.loading">
        <v-row>
          <v-skeleton-loader
            ref="skeleton"
            type="table"
            class="mx-auto"
          ></v-skeleton-loader>
        </v-row>
      </div> -->
			<v-data-table
        no-data-text="Aucun(s) OT(s) à lancer"
        hide-default-footer
        class="mb-2"
        dense
        locale="fr-FR"
      >
			</v-data-table>
    </v-card>

</template>

<style scoped>
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>

export default {
  name: "OTvalidate",
  data() {
		return {
			headers: [
        { text: "DATE", value: "" },
        { text: "IMMAT", value: "" },
        { text: "RAISON", value: "" },
			],
		};
  },
  apollo: {

  },
};
</script>
