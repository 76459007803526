import gql from 'graphql-tag';

export const aircraftsRequeteQuery = gql`
      {
        aircrafts {
          id
          status
          immatriculation
          easaType
          easaPartType
          mtow
          contact {
              id
              name
          }
          supervisor {
            id
            name
          }
          contract {
            id
            register
            contractType
          }
          aircraftDocuments {
            id
            typeName
            number
            dateDoc
            expiredAt
            document
          }
          aircraftPhotos {
            id
            isCover
           }  
          cell {
            id
            serialNumber
            manufacturingDate
            flyValue
            manufacturer {
              id
              name
            }
            partModel {
              id
              reference
              fullName
              mtow
              tcds
              tcHolder {
                id
                name
              }
            }
            manuals {
              id
              number
              title
            }
          }
          motors {
            id
            serialNumber
            partModel {
              id
              reference
              fullName
              tcHolder {
                id
                name
              }
             }
            manuals {
              id
              number
              title
            }
          }
          propellers {
            id
            serialNumber
            partModel {
              id
              reference
              fullName
              tcHolder {
                id
                name
              }
            }
            manuals {
              id
              number
              title
            }
          }
        }
      }`
