<template>
	<v-card class="mt-6" min-height="400" >
		<v-card-title class="text-h4 align-start">
			<v-sheet outlined	
				overflow="hidden"
				color="#003f5c"
				border-color="#003f5c"
				elevation="6"
				rounded
				height="60"
				width="60"
				class="mt-n8"
				> 
				<div class="pl-4 pt-2">
					<v-icon dark>mdi-file-sign</v-icon>
				</div>
			</v-sheet>
		</v-card-title>
		<v-card-text>
			<apexchart width="500" type="bar" :options="options" :series="series"></apexchart>
		</v-card-text>

	</v-card>
</template>

<style>
.v-sheet.v-card {
    border-radius: 6px;
}
</style>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: { apexchart: VueApexCharts  },
	data() {
		return {
			series: [{
          name: 'NCO',
          data: [ 136, 265 ]
        },	{
          name: 'DTO',
          data: [5, 7 ]
        },	{
          name: 'ATO',
          data: [9, 32 ]
        },{
          name: 'SPO',
          data: [11, 20]
        },	
		],
			options: {
				chart: {
					type: 'bar',
					stacked: true,
					stackType: '100%',
					height: 350,
					toolbar: {
						show: false,
					},
				},
				colors: ['#003f5c', '#7a5195', '#ef5675', '#ffa600'],
				plotOptions: {
					bar: {
						horizontal: true,
					}
				},

				title: {
						text: 'répartition selon les types de contrats',
						align: 'left'
				},
				xaxis: {
					categories: [2023, 2024, 2025],
				},

			
			}
		}
	},
	computed: {		

	},
};

</script>