<template>
	<v-card class="mt-6" min-height="400">
		<v-card-title class="text-h4 align-start">
			<v-sheet outlined	
				overflow="hidden"
				color="#f6c85f"
				border-color="#f6c85f"
				elevation="6"
				rounded
				height="60"
				width="60"
				class="mt-n8"
				>
				<div class="pl-4 pt-2">
					<v-icon dark>mdi-airplane</v-icon>
				</div>
			</v-sheet>
		</v-card-title>
		<v-card-text>
				<apexchart width="500" type="bar" :options="options" :series="series"></apexchart>
		</v-card-text>

	</v-card>
</template>
				
<style>
.v-sheet.v-card {
	border-radius: 6px;
}
</style>		

<script>

import VueApexCharts from "vue-apexcharts";

export default {	
	components: {apexchart: VueApexCharts },
	data() {
		return {
			series: [
				{
					name: 'Aéronef en déposé',
					data: [140, 237, 109, 145, ]
				},
				{
					name: 'Aéronef en commercial',	
					data: [	125, 95, 181, 184, ]
				},
			],
			options: {
				chart: {
					type: 'bar',
					stacked: true,
					toolbar: {
						show: false,
					},
				},
				colors: ['#0b83a5', '#f6c85f'],
				xaxis: {
					categories: ['2020', '2021', '2022', '2023', '2024'],
				},
				dataLabels: {
					enabled: true
				},
				title: {
					text: 'Nombre d\'aéronefs',
					align: 'left'
				},
			},
		}
	},
	computed: {		
	},
	methods: {		
	
	},
	apollo: {
   
  },
};
</script>

