<template>
	<v-card class="mt-6" min-height="400">
		<v-card-title class="text-h4 align-start">
			<v-sheet outlined	
				overflow="hidden"
				color="#ba2e0f"
				border-color="#ba2e0f"
				elevation="6"
				rounded
				height="60"
				width="60"
				class="mt-n8"
				>
				<div class="pl-4 pt-2">
					<v-icon dark>mdi-airplane</v-icon>
				</div>
			</v-sheet>
		</v-card-title>
		<v-card-text>
				<apexchart width="500" type="bar" :options="options" :series="series"></apexchart>
		</v-card-text>

	</v-card>
</template>
				
<style>
.v-sheet.v-card {
	border-radius: 6px;
}
</style>		

<script>

import { lastAircraftsQuery } from "../../graphql/aircraft/last_aircrafts";
import VueApexCharts from "vue-apexcharts";

export default {	
	components: {apexchart: VueApexCharts },
	data() {
		return {
			series: [
				{
					name: 'Entrée',
					data: [5, 6, 7, 8, 9, 0, 1, 3, 1, 0, 5, 1]
				},
				{
					name: 'Sortie',	
					data: [	-5, -1, -3, -2, -4 ,-2, -0, -3 , -1, -2, -3, -4]
				},
			],
			options: {
				chart: {
					type: 'bar',
					stacked: true,
					toolbar: {
						show: false,
					},
				},
				colors: ['#69ae34', '#ba2e0f'],
				xaxis: {
					categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
				},
				stroke: {
					curve: 'smooth'
				},
				dataLabels: {
					enabled: false
				},

				title: {
					text: 'Entrée / sortie de l\'année en cours',
					align: 'left'
				},
			},
		}
	},
	computed: {		
	},
	methods: {		
		updateSeries() {
			this.series = [
				{
					name: 'Nouveaux Contrats par Mois',
					data: this.lastAircrafts.reduce((acc, aircraft) => {
						const month = new Date(aircraft.aircraftContracts.start).getMonth();
						acc[month] = (acc[month] || 0) + 1;
						return acc;
					}, new Array(12).fill(0))
				},
				{
					name: 'Contrats cloturés par Mois',
					data: this.lastAircrafts.reduce((acc, aircraft) => {
						if (aircraft.aircraftContracts.closedAt) {
							const month = new Date(aircraft.aircraftContracts.closedAt).getMonth();
							acc[month] = (acc[month] || 0) + 1;
						}		}, new Array(12).fill(0))	
				},	
			];
    }		
	},
	apollo: {
    lastAircrafts: {
      query: lastAircraftsQuery,
    },
  },
};
</script>

