<template>
  <div v-if="cen">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pt-0">
          <!-- titre -->
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-card-title
                class="justify-center text-center font-weight-black background pt-0"
              >
                CERTIFICAT D'EXAMEN DE NAVIGABILITE<br />{{ cen.reference }}
              </v-card-title>
              <v-card-subtitle class="text-center background pb-0">
                Veuillez remplir tous les champs afin de compléter le certificat
                de navigabilité
              </v-card-subtitle>
            </v-col>
          </v-row>
          <!-- initial -->
          <v-row v-if="cen.action == 'renew'">
            <v-col cols="12">
              <v-card-subtitle class="font-weight-black pb-0 pt-0">
                CERTIFICAT INITIAL</v-card-subtitle
              >
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row v-if="cen.action == 'renew'">
            <v-col cols="3" class="mt-4 py-0">
              <v-autocomplete
                v-if="partModels"
                v-model="partModel"
                :items="
                  partModels
                    .map(function (t) {
                      return {
                        text: t.tcHolder.name + ' - ' + t.reference,
                        value: t.id,
                      };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text))
                "
                label="Modèle"
                required
                outlined
                dense
                @change="$v.partModel.$touch()"
                @blur="$v.partModel.$touch()"
                :error-messages="partModelErrors"
                :success="!!partModel"
                clearable
                :disabled="cen.aircraft != null"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="mt-4 py-0">
              <v-text-field
                v-model="cellSerialNumber"
                label="S/N"
                outlined
                dense
                @change="$v.cellSerialNumber.$touch()"
                @blur="$v.cellSerialNumber.$touch()"
                :error-messages="cellSerialNumberErrors"
                :success="!!cellSerialNumber"
                clearable
                :disabled="cen.aircraft != null"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="mt-4 py-0">
              <v-select
                v-model="manufacturer"
                label="Constructeur"
                outlined
                dense
                @change="$v.manufacturer.$touch()"
                @blur="$v.manufacturer.$touch()"
                :error-messages="manufacturerErrors"
                :success="!!manufacturer"
                clearable
                v-if="partModel && partModels.length > 0"
                :items="
                  partModels
                    .find((p) => p.id == partModel)
                    .tcHolder.manufacturers.map(function (m) {
                      return { text: m.name, value: m.id };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text))
                "
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="cen.action == 'renew'">
            <v-col cols="2" class="py-0">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="deliveryDatePicker"
                    label="Date de délivrance"
                    v-on="on"
                    outlined
                    dense
                    @change="$v.deliveryDate.$touch()"
                    @blur="$v.deliveryDate.$touch()"
                    :error-messages="deliveryDateErrors"
                    :success="!!deliveryDatePicker"
                    clearable
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="deliveryDate"
                  :first-day-of-week="1"
                  locale="fr"
                  color="green"
                  no-title
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-text-field
                label="Date d'expiration"
                disabled
                v-model="expirationDate"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-text-field
                label="Référence CEN"
                requis
                dense
                outlined
                @change="$v.referenceCen.$touch()"
                @blur="$v.referenceCen.$touch()"
                :error-messages="referenceCenErrors"
                :success="!!referenceCen"
                clearable
                v-model="referenceCen"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-text-field
                label="HH"
                requis
                dense
                outlined
                @change="$v.cellHours.$touch()"
                @blur="$v.cellHours.$touch()"
                :error-messages="cellHoursErrors"
                :success="!!cellHours"
                clearable
                v-model="cellHours"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1" class="py-0">
              <v-text-field
                label="mm"
                requis
                dense
                outlined
                @change="$v.cellMinutes.$touch()"
                @blur="$v.cellMinutes.$touch()"
                :error-messages="cellMinutesErrors"
                :success="!!cellMinutes"
                clearable
                v-model="cellMinutes"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- 1er extension -->
          <div v-if="cen.action == 'extension-1'">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-subtitle class="font-weight-black pb-0 pt-0">
                  1er EXTENSION</v-card-subtitle
                ><v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="mt-4 py-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="deliveryDatePicker"
                      label="Date de délivrance"
                      v-on="on"
                      outlined
                      dense
                      @change="$v.deliveryDate.$touch()"
                      @blur="$v.deliveryDate.$touch()"
                      :error-messages="deliveryDateErrors"
                      :success="!!deliveryDatePicker"
                      clearable
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="deliveryDate"
                    :first-day-of-week="1"
                    locale="fr"
                    color="green"
                    no-title
                    @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" class="mt-4 py-0">
                <v-text-field
                  label="Date d'expiration"
                  disabled
                  v-model="expirationDate"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="mt-4 py-0">
                <v-text-field
                  label="HH"
                  requis
                  dense
                  outlined
                  @change="$v.cellHoursExt1.$touch()"
                  @blur="$v.cellHoursExt1.$touch()"
                  :error-messages="cellHoursExt1Errors"
                  :success="!!cellHoursExt1"
                  clearable
                  v-model="cellHoursExt1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="mt-4 py-0">
                <v-text-field
                  label="mm"
                  requis
                  dense
                  outlined
                  @change="$v.cellMinutesExt1.$touch()"
                  @blur="$v.cellMinutesExt1.$touch()"
                  :error-messages="cellMinutesExt1Errors"
                  :success="!!cellMinutesExt1"
                  clearable
                  v-model="cellMinutesExt1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-0 mt-0">
                <v-checkbox
                  dense
                  v-model="noFly"
                  label="Forcer la date d'expiration (maintenance longue)"
                  :false-value="false"
                  :true-value="true"
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
          <!-- 2eme extension -->
          <div v-if="cen.action == 'extension-2'">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-subtitle class="font-weight-black pb-0 pt-0">
                  2eme EXTENSION</v-card-subtitle
                ><v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="mt-4 py-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="deliveryDatePicker"
                      label="Date de délivrance"
                      v-on="on"
                      outlined
                      dense
                      @change="$v.deliveryDate.$touch()"
                      @blur="$v.deliveryDate.$touch()"
                      :error-messages="deliveryDateErrors"
                      :success="!!deliveryDatePicker"
                      clearable
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="deliveryDate"
                    :first-day-of-week="1"
                    locale="fr"
                    color="green"
                    no-title
                    @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" class="mt-4 py-0">
                <v-text-field
                  label="Date d'expiration"
                  disabled
                  v-model="expirationDate"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="mt-4 py-0">
                <v-text-field
                  label="HH"
                  requis
                  dense
                  outlined
                  @change="$v.cellHoursExt2.$touch()"
                  @blur="$v.cellHoursExt2.$touch()"
                  :error-messages="cellHoursExt2Errors"
                  :success="!!cellHoursExt2"
                  clearable
                  v-model="cellHoursExt2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="mt-4 py-0">
                <v-text-field
                  label="mm"
                  requis
                  dense
                  outlined
                  @change="$v.cellMinutesExt2.$touch()"
                  @blur="$v.cellMinutesExt2.$touch()"
                  :error-messages="cellMinutesExt2Errors"
                  :success="!!cellMinutesExt2"
                  clearable
                  v-model="cellMinutesExt2"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-0 mt-0">
                <v-checkbox
                  dense
                  v-model="noFly"
                  label="Forcer la date d'expiration (maintenance longue)"
                  :false-value="false"
                  :true-value="true"
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12">
          <div v-if="cen.cell">
            <!-- titre -->
            <v-row>
              <v-col cols="12">
                <v-card-title
                  class="justify-center font-weight-black background pt-0"
                >
                  BORDEREAU
                </v-card-title>
                <v-card-subtitle class="text-center background pb-0">
                  Veuillez remplir tous les champs afin de compléter le
                  bordereau pour l'OSAC
                </v-card-subtitle>
              </v-col>
            </v-row>
            <!-- moteur/helice 1 -->

            <v-row>
              <v-col cols="12">
                <v-card-subtitle class="font-weight-black pb-0 pt-0">
                  MOTEUR ET HELICE</v-card-subtitle
                >

                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="mt-4 py-0">
                <v-autocomplete
                  v-if="partModelsMotor"
                  v-model="motor1PartModel"
                  :items="
                    partModelsMotor
                      .map(function (t) {
                        return {
                          text: t.tcHolder.name + ' - ' + t.reference,
                          value: t.id,
                        };
                      })
                      .sort((a, b) => a.text.localeCompare(b.text))
                  "
                  label="Modèle du moteur"
                  required
                  outlined
                  dense
                  :success="!!motor1PartModel"
                  clearable
                  :disabled="cen.aircraft != null"
                  @change="$v.motor1PartModel.$touch()"
                  @blur="$v.motor1PartModel.$touch()"
                  :error-messages="motor1PartModelErrors"
                ></v-autocomplete>
              </v-col>

              <v-col cols="3" class="mt-4 py-0">
                <v-text-field
                  v-model="motor1SerialNumber"
                  label="S/N"
                  outlined
                  dense
                  :success="!!motor1SerialNumber"
                  clearable
                  :disabled="cen.aircraft != null"
                  @change="$v.motor1SerialNumber.$touch()"
                  @blur="$v.motor1SerialNumber.$touch()"
                  :error-messages="motor1SerialNumberErrors"
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="mt-4 py-0">
                <v-text-field
                  label="HH"
                  requis
                  dense
                  outlined
                  @change="$v.motor1Hours.$touch()"
                  @blur="$v.motor1Hours.$touch()"
                  :error-messages="motor1HoursErrors"
                  v-model="motor1Hours"
                  :success="!!motor1Hours"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="mt-4 py-0">
                <v-text-field
                  label="mm"
                  requis
                  dense
                  outlined
                  @change="$v.motor1Minutes.$touch()"
                  @blur="$v.motor1Minutes.$touch()"
                  :error-messages="motor1MinutesErrors"
                  v-model="motor1Minutes"
                  :success="!!motor1Minutes"
                  clearable
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="py-0">
                <v-autocomplete
                  v-if="partModelsPropeller"
                  v-model="propeller1PartModel"
                  :items="
                    partModelsPropeller
                      .map(function (t) {
                        return {
                          text: t.tcHolder.name + ' - ' + t.reference,
                          value: t.id,
                        };
                      })
                      .sort((a, b) => a.text.localeCompare(b.text))
                  "
                  label="Modèle de l'hélice"
                  required
                  outlined
                  dense
                  :success="!!propeller1PartModel"
                  clearable
                  :disabled="cen.aircraft != null"
                ></v-autocomplete>
              </v-col>

              <v-col cols="3" class="py-0">
                <v-text-field
                  v-model="propeller1SerialNumber"
                  label="S/N"
                  outlined
                  dense
                  :success="!!propeller1SerialNumber"
                  clearable
                  :disabled="cen.aircraft != null"
                  @change="$v.propeller1SerialNumber.$touch()"
                  @blur="$v.propeller1SerialNumber.$touch()"
                  :error-messages="propeller1SerialNumberErrors"                  
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="py-0">
                <v-text-field
                  label="HH"
                  requis
                  dense
                  outlined
                  @change="$v.propeller1Hours.$touch()"
                  @blur="$v.propeller1Hours.$touch()"
                  :error-messages="propeller1HoursErrors"
                  :success="!!propeller1Hours"
                  clearable
                  v-model="propeller1Hours"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="py-0">
                <v-text-field
                  label="mm"
                  requis
                  dense
                  outlined
                  @change="$v.propeller1Minutes.$touch()"
                  @blur="$v.propeller1Minutes.$touch()"
                  :error-messages="propeller1MinutesErrors"
                  v-model="propeller1Minutes"
                  :success="!!propeller1Minutes"
                  clearable
                >
                </v-text-field>
              </v-col>
            </v-row>

            <!-- moteur/helice 2 -->
            <template v-if="cen.cell.numberMotor == 2" class="ml-1">
              <v-row>
                <v-col cols="12">
                  <v-card-subtitle class="font-weight-black pb-1 pt-2">
                    MOTEUR ET HELICE 2</v-card-subtitle
                  ><v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5" class="mt-4 py-0">
                  <v-autocomplete
                    v-if="partModelsMotor"
                    v-model="motor2PartModel"
                    :items="
                      partModelsMotor
                        .map(function (t) {
                          return {
                            text: t.tcHolder.name + ' - ' + t.reference,
                            value: t.id,
                          };
                        })
                        .sort((a, b) => a.text.localeCompare(b.text))
                    "
                    label="Modèle du moteur"
                    required
                    outlined
                    dense
                    :success="!!motor2PartModel"
                    clearable
                    :disabled="cen.aircraft != null"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="3" class="mt-4 py-0">
                  <v-text-field
                    v-model="motor2SerialNumber"
                    label="S/N"
                    outlined
                    dense
                    :success="!!motor2SerialNumber"
                    clearable
                    :disabled="cen.aircraft != null"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="mt-4 py-0">
                  <v-text-field
                    label="HH"
                    requis
                    dense
                    outlined
                    @change="$v.motor2Hours.$touch()"
                    @blur="$v.motor2Hours.$touch()"
                    :error-messages="motor2HoursErrors"
                    :success="!!motor2Hours"
                    clearable
                    v-model="motor2Hours"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2" class="mt-4 py-0">
                  <v-text-field
                    label="mm"
                    requis
                    dense
                    outlined
                    @change="$v.motor2Minutes.$touch()"
                    @blur="$v.motor2Minutes.$touch()"
                    :error-messages="motor2MinutesErrors"
                    :success="!!motor2Minutes"
                    clearable
                    v-model="motor2Minutes"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5" class="py-0">
                  <v-autocomplete
                    v-if="partModelsPropeller"
                    v-model="propeller2PartModel"
                    :items="
                      partModelsPropeller
                        .map(function (t) {
                          return {
                            text: t.tcHolder.name + ' - ' + t.reference,
                            value: t.id,
                          };
                        })
                        .sort((a, b) => a.text.localeCompare(b.text))
                    "
                    label="Modèle de l'hélice"
                    required
                    outlined
                    dense
                    :success="!!propeller2PartModel"
                    clearable
                    :disabled="cen.aircraft != null"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="3" class="py-0">
                  <v-text-field
                    v-model="propeller2SerialNumber"
                    label="S/N"
                    outlined
                    dense
                    :success="!!propeller2SerialNumber"
                    clearable
                    :disabled="cen.aircraft != null"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field
                    label="HH"
                    requis
                    dense
                    outlined
                    @change="$v.propeller2Hours.$touch()"
                    @blur="$v.propeller2Hours.$touch()"
                    :error-messages="propeller2HoursErrors"
                    :success="!!propeller2Hours"
                    clearable
                    v-model="propeller2Hours"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field
                    label="mm"
                    requis
                    dense
                    outlined
                    @change="$v.propeller2Minutes.$touch()"
                    @blur="$v.propeller2Minutes.$touch()"
                    :error-messages="propeller2MinutesErrors"
                    :success="!!propeller2Minutes"
                    clearable
                    v-model="propeller2Minutes"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col cols="5" class="py-0">
                <div>
                  L'Aéronef est-il soumis aux exigences de limitation de
                  nuisances sonores ?
                </div>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-radio-group dense v-model="noiseLimit" row class="mt-0">
                  <v-radio
                    class="pl-3"
                    color="success"
                    label="Oui"
                    :value="true"
                  >
                    <template v-slot:label>
                      <div v-if="noiseLimit == true">
                        <strong class="success--text">Oui</strong>
                      </div>
                      <div v-else>Oui</div>
                    </template>
                  </v-radio>
                  <v-radio
                    class="pl-3"
                    color="error"
                    label="Non"
                    :value="false"
                  >
                    <template v-slot:label>
                      <div v-if="noiseLimit == false">
                        <strong class="error--text">Non</strong>
                      </div>
                      <div v-else>Non</div>
                    </template>
                  </v-radio></v-radio-group
                >
              </v-col>
            </v-row>
            <!-- changement -->
            <v-row>
              <v-col cols="5" class="py-0">
                <div>
                  Y-a-t-il eu des changements au cours des 12 derniers mois ?
                </div>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-radio-group dense v-model="change" row class="mt-0">
                  <v-radio
                    class="pl-3"
                    color="success"
                    label="Oui"
                    :value="true"
                  >
                    <template v-slot:label>
                      <div v-if="change == true">
                        <strong class="success--text">Oui</strong>
                      </div>
                      <div v-else>Oui</div>
                    </template>
                  </v-radio>

                  <v-radio
                    class="pl-3"
                    color="error"
                    label="Non"
                    :value="false"
                  >
                    <template v-slot:label>
                      <div v-if="change == false">
                        <strong class="error--text">Non</strong>
                      </div>
                      <div v-else>Non</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="change == true">
              <v-col cols="6" class="py-0">
                <v-textarea
                  label="Changement de propriétaire"
                  rows="3"
                  auto-grow
                  outlined
                  v-model="ownerChange"
                  :success="!!ownerChange"
                  clearable
                ></v-textarea>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-textarea
                  label="Changement d'exploitant"
                  rows="3"
                  auto-grow
                  outlined
                  v-model="operatorChange"
                  :success="!!operatorChange"
                  clearable
                ></v-textarea>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-textarea
                  label="Changement de mécanicien"
                  rows="3"
                  auto-grow
                  outlined
                  v-model="mecanicChange"
                  :success="!!mecanicChange"
                  clearable
                ></v-textarea>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-textarea
                  label="Changement de CAMO"
                  rows="3"
                  auto-grow
                  outlined
                  v-model="navOperatorChange"
                  :success="!!navOperatorChanges"
                  clearable
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12" align="center">
          <v-btn
            class="mr-4 primary--text"
            outlined
            depressed
            :exact="true"
            :to="{ name: 'cen', params: { id: cen.id, page: 'info' } }"
            >Annuler</v-btn
          >
          <v-btn class="mr-4 primary" dark @click="updateCen()"
            >Enregistrer</v-btn
          >
          <!-- <v-snackbar
            v-model="snackbar"
            :timeout="6000"
            rounded="pill"
            color="red"
            top
            app
            text
          >
            <span class="text-center">{{ text }}</span>
            <template v-slot:action="{ attrs }">
              <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                OK
              </v-btn>
            </template> 
          </v-snackbar> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { cenQuery } from "../../graphql/cen/cen";
import { partModelsQuery } from "@/graphql/part_model/part_models";
import { updateCenMutation } from "@/graphql/cen/update_cen";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";

export default {
  name: "CENFinalisation",
  mixins: [validationMixin],
  data: () => {
    return {
      cen: null,
      open: false,
      partModel: null,
      partModels: [],
      partModelsMotor: [],
      partModelsPropeller: [],
      cellHours: null,
      cellMinutes: null,
      cellHoursExt1: null,
      cellMinutesExt1: null,
      cellHoursExt2: null,
      cellMinutesExt2: null,
      menu: null,
      menuExt1D: null,
      menuExt2D: null,
      cellSerialNumber: null,
      manufacturer: null,
      motor1PartModel: null,
      motor1SerialNumber: null,
      motor1Hours: null,
      motor1Minutes: null,
      motor2PartModel: null,
      motor2SerialNumber: null,
      motor2Hours: null,
      motor2Minutes: null,
      propeller1PartModel: null,
      propeller1SerialNumber: null,
      propeller1Hours: null,
      propeller1Minutes: null,
      propeller2PartModel: null,
      propeller2SerialNumber: null,
      propeller2Hours: null,
      propeller2Minutes: null,
      noiseLimit: false,
      change: false,
      ownerChange: null,
      operatorChange: null,
      mecanicChange: null,
      navOperatorChange: null,
      snackbar: false,
      text: "Vos modifications ont bien été enregistrées",
      timeout: 2000,
      expirationDate: null,
      referenceCen: null,
      deliveryDate: null,
      noFly: false,
    };
  },
  validations() {
    let val = {
      deliveryDate: { required },
    };
    if (!this.cen.aircraft) {
      val["partModel"] = { required };
      val["cellSerialNumber"] = { required };
    }

    if (!this.cen.aircraft && this.cen.cell) {
      val["motor1PartModel"] = { required };
    }

    if (this.propeller1PartModel) {
      val["propeller1SerialNumber"] = { required };
      val["propeller1Hours"] = { required, numeric };
      val["propeller1Minutes"] = { required, numeric };
    }

    if (this.motor1PartModel) {
      val["motor1SerialNumber"] = { required };
      val["motor1Hours"] = { required, numeric };
      val["motor1Minutes"] = { required, numeric };
    }

    if (this.motor2PartModel) {
      val["motor2Hours"] = { required, numeric };
      val["motor2Minutes"] = { required, numeric };
    }
    if (this.propeller2PartModel) {
      val["propeller2Hours"] = { required };
      val["propeller2Minutes"] = { required };
    }

    if (this.cen.action == "renew") {
      val["manufacturer"] = { required };
      val["referenceCen"] = { required };
      val["cellHours"] = { required, numeric };
      val["cellMinutes"] = { required, numeric };
    }
    if (this.cen.action == "extension-1") {
      val["cellHoursExt1"] = { required, numeric };
      val["cellMinutesExt1"] = { required, numeric };
    }
    if (this.cen.action == "extension-2") {
      val["cellHoursExt2"] = { required, numeric };
      val["cellMinutesExt2"] = { required, numeric };
    }
    return val;
  },
  methods: {
    cellTotalHours(hours, minutes) {
      if (!hours && !minutes) {
        return null;
      }
      var totalMinutes = 0;
      if (hours) {
        totalMinutes += parseInt(hours, 10) * 60;
      }
      if (minutes) {
        totalMinutes += parseInt(minutes, 10);
      }
      return totalMinutes;
    },
    setHoursAndMinutes(total) {
      const minutes = total % 60;
      const hours = Math.floor(total / 60);
      return {
        hours: hours,
        minutes: minutes,
      };
    },
    handleClose() {
      this.open = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    updateCen() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$apollo
          .mutate({
            mutation: updateCenMutation,
            variables: {
              input: {
                id: parseInt(this.cen.id, 10),
                attributes: {
                  cellId: this.partModel,
                  cellManufacturerId: this.manufacturer,
                  cellSerialNumber: this.cellSerialNumber,
                  cellTotalHours: this.cellTotalHours(
                    this.cellHours,
                    this.cellMinutes
                  ),
                  cellTotalHoursExt1: this.cellTotalHours(
                    this.cellHoursExt1,
                    this.cellMinutesExt1
                  ),
                  cellTotalHoursExt2: this.cellTotalHours(
                    this.cellHoursExt2,
                    this.cellMinutesExt2
                  ),
                  deliveryDate: this.deliveryDate,
                  motor1Id: this.motor1PartModel,
                  motor2Id: this.motor2PartModel,
                  propeller1Id: this.propeller1PartModel,
                  propeller2Id: this.propeller2PartModel,
                  motor1SerialNumber: this.motor1SerialNumber,
                  motor2SerialNumber: this.motor2SerialNumber,
                  propeller1SerialNumber: this.propeller1SerialNumber,
                  propeller2SerialNumber: this.propeller2SerialNumber,
                  noiseLimit: this.noiseLimit,
                  motor1TotalHours: this.cellTotalHours(
                    this.motor1Hours,
                    this.motor1Minutes
                  ),
                  motor2TotalHours: this.cellTotalHours(
                    this.motor2Hours,
                    this.motor2Minutes
                  ),
                  propeller1TotalHours: this.cellTotalHours(
                    this.propeller1Hours,
                    this.propeller1Minutes
                  ),
                  propeller2TotalHours: this.cellTotalHours(
                    this.propeller2Hours,
                    this.propeller2Minutes
                  ),
                  ownerChange: this.ownerChange,
                  navOperatorChange: this.navOperatorChange,
                  mecanicChange: this.mecanicChange,
                  operatorChange: this.operatorChange,
                  referenceCen: this.referenceCen,
                  noFly: this.noFly,
                },
              },
            },
          })
          .then(() => {
            this.$router.push({ name: "cen", params: { id: this.cen.id } });
            //this.snackbar = true;
          });
      }
    },
  },
  computed: {
    partModelErrors() {
      const errors = [];
      if (!this.$v.partModel) return errors;
      if (!this.$v.partModel.$dirty) return errors;
      !this.$v.partModel.required && errors.push("Manquant");
      return errors;
    },
    cellSerialNumberErrors() {
      const errors = [];
      if (!this.$v.cellSerialNumber) return errors;
      if (!this.$v.cellSerialNumber.$dirty) return errors;
      !this.$v.cellSerialNumber.required && errors.push("Manquant");
      return errors;
    },
    deliveryDatePicker() {
      return this.formatDate(this.deliveryDate);
    },
    deliveryDateErrors() {
      const errors = [];
      if (!this.$v.deliveryDate.$dirty) return errors;
      !this.$v.deliveryDate.required && errors.push("Manquant");
      return errors;
    },
    manufacturerErrors() {
      const errors = [];
      if (!this.$v.manufacturer.$dirty) return errors;
      !this.$v.manufacturer.required && errors.push("Manquant");
      return errors;
    },
    referenceCenErrors() {
      const errors = [];
      if (!this.$v.referenceCen.$dirty) return errors;
      !this.$v.referenceCen.required && errors.push("Manquant");
      return errors;
    },
    cellHoursErrors() {
      const errors = [];
      if (!this.$v.cellHours.$dirty) return errors;
      !this.$v.cellHours.required && errors.push("Manquant");
      !this.$v.cellHours.numeric && errors.push("Que des chiffres");
      return errors;
    },
    cellMinutesErrors() {
      const errors = [];
      if (!this.$v.cellMinutes.$dirty) return errors;
      !this.$v.cellMinutes.required && errors.push("Manquant");
      !this.$v.cellMinutes.numeric && errors.push("Que des chiffres");
      return errors;
    },
    cellHoursExt1Errors() {
      const errors = [];
      if (!this.$v.cellHoursExt1.$dirty) return errors;
      !this.$v.cellHoursExt1.required && errors.push("Manquant");
      !this.$v.cellHoursExt1.numeric && errors.push("Que des chiffres");
      return errors;
    },
    cellMinutesExt1Errors() {
      const errors = [];
      if (!this.$v.cellMinutesExt1.$dirty) return errors;
      !this.$v.cellMinutesExt1.required && errors.push("Manquant");
      !this.$v.cellMinutesExt1.numeric && errors.push("Que des chiffres");
      return errors;
    },
    cellHoursExt2Errors() {
      const errors = [];
      if (!this.$v.cellHoursExt2.$dirty) return errors;
      !this.$v.cellHoursExt2.required && errors.push("Manquant");
      !this.$v.cellHoursExt2.numeric && errors.push("Que des chiffres");
      return errors;
    },
    cellMinutesExt2Errors() {
      const errors = [];
      if (!this.$v.cellMinutesExt2.$dirty) return errors;
      !this.$v.cellMinutesExt2.required && errors.push("Manquant");
      !this.$v.cellMinutesExt2.numeric && errors.push("Que des chiffres");
      return errors;
    },
    motor1HoursErrors() {
      const errors = [];
      if (!this.$v.motor1Hours) return errors;
      if (!this.$v.motor1Hours.$dirty) return errors;
      !this.$v.motor1Hours.required && errors.push("Manquant");
      !this.$v.motor1Hours.numeric && errors.push("Que des chiffres");
      return errors;
    },
    motor1MinutesErrors() {
      const errors = [];
      if (!this.$v.motor1Minutes) return errors;
      if (!this.$v.motor1Minutes.$dirty) return errors;
      !this.$v.motor1Minutes.required && errors.push("Manquant");
      !this.$v.motor1Minutes.numeric && errors.push("Que des chiffres");
      return errors;
    },
    propeller1SerialNumberErrors() {
      const errors = [];
      if (!this.$v.propeller1SerialNumber) return errors;
      if (!this.$v.propeller1SerialNumber.$dirty) return errors;
      !this.$v.propeller1SerialNumber.required && errors.push("Manquant");
      return errors;
    },
    propeller1HoursErrors() {
      const errors = [];
      if (!this.$v.propeller1Hours) return errors;
      if (!this.$v.propeller1Hours.$dirty) return errors;
      !this.$v.propeller1Hours.required && errors.push("Manquant");
      !this.$v.propeller1Hours.numeric && errors.push("Que des chiffres");
      return errors;
    },
    propeller1MinutesErrors() {
      const errors = [];
      if (!this.$v.propeller1Minutes) return errors;
      if (!this.$v.propeller1Minutes.$dirty) return errors;
      !this.$v.propeller1Minutes.required && errors.push("Manquant");
      !this.$v.propeller1Minutes.numeric && errors.push("Que des chiffres");
      return errors;
    },
    motor1PartModelErrors() {
      const errors = [];
      if (!this.$v.motor1PartModel) return errors;
      if (!this.$v.motor1PartModel.$dirty) return errors;
      !this.$v.motor1PartModel.required && errors.push("Manquant");
      return errors;
    },
    motor1SerialNumberErrors() {
      const errors = [];
      if (!this.$v.motor1SerialNumber) return errors;
      if (!this.$v.motor1SerialNumber.$dirty) return errors;
      !this.$v.motor1SerialNumber.required && errors.push("Manquant");
      return errors;
    },
    motor2HoursErrors() {
      const errors = [];
      if (!this.$v.motor2Hours) return errors;
      if (!this.$v.motor2Hours.$dirty) return errors;
      !this.$v.motor2Hours.required && errors.push("Manquant");
      !this.$v.motor2Hours.numeric && errors.push("Que des chiffres");
      return errors;
    },
    motor2MinutesErrors() {
      const errors = [];
      if (!this.$v.motor2Minutes) return errors;
      if (!this.$v.motor2Minutes.$dirty) return errors;
      !this.$v.motor2Minutes.required && errors.push("Manquant");
      !this.$v.motor2Minutes.numeric && errors.push("Que des chiffres");
      return errors;
    },
    propeller2HoursErrors() {
      const errors = [];
      if (!this.$v.propeller2Hours) return errors;
      if (!this.$v.propeller2Hours.$dirty) return errors;
      !this.$v.propeller2Hours.required && errors.push("Manquant");
      !this.$v.propeller2Hours.numeric && errors.push("Que des chiffres");
      return errors;
    },
    propeller2MinutesErrors() {
      const errors = [];
      if (!this.$v.propeller2Minutes) return errors;
      if (!this.$v.propeller2Minutes.$dirty) return errors;
      !this.$v.propeller2Minutes.required && errors.push("Manquant");
      !this.$v.propeller2Minutes.numeric && errors.push("Que des chiffres");
      return errors;
    },
  },
  apollo: {
    cen: {
      query: cenQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        console.log(data);
        if (data) {
          if (data.cen.cell) {
            this.partModel = data.cen.cell.id;
          }
          if (data.cen.cellManufacturer) {
            this.manufacturer = data.cen.cellManufacturer.id;
          }
          this.cellSerialNumber = data.cen.cellSerialNumber;
          this.deliveryDate = data.cen.deliveryDate;
          if (data.cen.cellTotalHours) {
            const hourData = this.setHoursAndMinutes(data.cen.cellTotalHours);
            this.cellMinutes = hourData.minutes;
            this.cellHours = hourData.hours;
          }
          if (data.cen.cellTotalHoursExt1) {
            const hourData = this.setHoursAndMinutes(
              data.cen.cellTotalHoursExt1
            );
            this.cellMinutesExt1 = hourData.minutes;
            this.cellHoursExt1 = hourData.hours;
          }
          if (data.cen.cellTotalHoursExt2) {
            const hourData = this.setHoursAndMinutes(
              data.cen.cellTotalHoursExt2
            );
            this.cellMinutesExt2 = hourData.minutes;
            this.cellHoursExt2 = hourData.hours;
          }
          if (data.cen.motor1) {
            this.motor1PartModel = data.cen.motor1.id;
          }
          if (data.cen.motor2) {
            this.motor2PartModel = data.cen.motor2.id;
          }
          if (data.cen.propeller1) {
            this.propeller1PartModel = data.cen.propeller1.id;
          }
          if (data.cen.propeller2) {
            this.propeller2PartModel = data.cen.propeller2.id;
          }
          this.motor1SerialNumber = data.cen.motor1SerialNumber;
          this.motor2SerialNumber = data.cen.motor2SerialNumber;
          this.propeller1SerialNumber = data.cen.propeller1SerialNumber;
          this.propeller2SerialNumber = data.cen.propeller2SerialNumber;
          if (data.cen.motor1TotalHours) {
            const hourData = this.setHoursAndMinutes(data.cen.motor1TotalHours);
            this.motor1Minutes = hourData.minutes;
            this.motor1Hours = hourData.hours;
          }
          if (data.cen.motor2TotalHours) {
            const hourData = this.setHoursAndMinutes(data.cen.motor2TotalHours);
            this.motor2Minutes = hourData.minutes;
            this.motor2Hours = hourData.hours;
          }
          if (data.cen.propeller1TotalHours) {
            const hourData = this.setHoursAndMinutes(
              data.cen.propeller1TotalHours
            );
            this.propeller1Minutes = hourData.minutes;
            this.propeller1Hours = hourData.hours;
          }
          if (data.cen.propeller2TotalHours) {
            const hourData = this.setHoursAndMinutes(
              data.cen.propeller2TotalHours
            );
            this.propeller2Minutes = hourData.minutes;
            this.propeller2Hours = hourData.hours;
          }
        }
        this.noiseLimit = data.cen.noiseLimit;
        this.ownerChange = data.cen.ownerChange;
        this.mecanicChange = data.cen.mecanicChange;
        this.navOperatorChange = data.cen.navOperatorChange;
        this.operatorChange = data.cen.operatorChange;
        this.referenceCen = data.cen.referenceCen;
        this.expirationDate = moment(data.cen.expiredAt).format("DD/MM/YYYY");
        this.noFly = data.cen.noFly;
      },
    },
    partModels: {
      query: partModelsQuery,
      variables: {
        serie: false,
        type: ["Cell"],
        tcHolderId: null,
      },
    },
    partModelsMotor: {
      query: partModelsQuery,
      variables: {
        serie: false,
        type: ["Motor"],
        tcHolderId: null,
      },
      update: (data) => data.partModels,
    },
    partModelsPropeller: {
      query: partModelsQuery,
      variables: {
        serie: false,
        type: ["Propeller"],
        tcHolderId: null,
      },
      update: (data) => data.partModels,
    },
  },
};
</script>